
import ai from "../src/images/ai.jpg";
import ml from "../src/images/ml.jpg";
import droneSystem from "../src/images/drone.jpg";
import webSystem from "../src/images/file.jpg";
import appSystem from "../src/images/appdev.jpg";
import iot from "../src/images/iot.jpg";

const Sdata = [
  {
    imgsrc: ai,
    title: "Artificial Intelligence",
    CardTitle: "Elevate Your AI Knowledge with Expert Guidance"
  },
  {
    imgsrc: ml,
    title: "Machine Learning",
    CardTitle: "Deepen Your Machine Learning Insights with Us"
  },
  {
    imgsrc: droneSystem,
    title: "Drone System",
    CardTitle: "Master Drone Technologies with Professional Support"
  },
  {
    imgsrc: webSystem,
    title: "Web System",
    CardTitle: "Advance Your Web System Skills with Cutting-Edge Techniques"
  },
  {
    imgsrc: appSystem,
    title: "App System",
    CardTitle: "Enhance Your App Development Proficiency with Expert Help"
  },
  {
    imgsrc: iot,
    title: "Internet of Things",
    CardTitle: "Expand Your IoT Knowledge with Comprehensive Training"
  },
];

export default Sdata;
